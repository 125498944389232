export const useLoadingStore = defineStore('loading', () => {
  const isLoading = ref(false);
  const message = ref('')

  function setMessage (msg) {
    message.value = msg
  }


  function startLoading(msg?) {
    if (msg) {
      setMessage(msg)
    }
    isLoading.value = true;
  }

  function stopLoading() {
    isLoading.value = false;
  }

  return {
    isLoading,
    message,
    setMessage,
    startLoading,
    stopLoading,
  };
});
